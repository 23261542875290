@import './../../../../scss/theme-bootstrap';

$hex-width: 40px;
$hex-per-row: 8;

.product-shade-picker {
  @include pie-clearfix;
  &__label {
    font-size: 4vw;
    position: relative;
    overflow: hidden;
    @include breakpoint($medium-up) {
      font-size: 100%;
    }
    @include breakpoint($tablet-only) {
      font-size: 1.2vw;
    }
    &:after {
      position: absolute;
      content: '';
      margin-top: 3.3%;
      float: $rdirection;
      opacity: 0.5;
      margin-#{$ldirection}: 25px;
      border-top: 1px solid $color-white;
      width: 54%;
      @include breakpoint($medium-up) {
        margin-top: 3%;
      }
      @include breakpoint($tablet-only) {
        width: 46%;
        margin-top: 2.7%;
      }
    }
  }
  &__smoosh {
    margin: 0 auto 20px;
    &-img {
      display: block;
    }
  }
  &__shades {
    @include pie-clearfix;
    font-size: 0;
    white-space: nowrap;
    width: 100%;
    @include breakpoint($medium-up) {
      // Max width of the number of hexes we want per row
      // times the hex width, plus 8 margin offset per hex
      max-width: ($hex-width * $hex-per-row + (8 * $hex-per-row));
      width: auto;
    }
  }
  &__shade {
    float: #{$ldirection};
    width: 12%;
    height: 10vw;
    margin-#{$ldirection}: 5.5%;
    margin-bottom: 7px;
    @include breakpoint($medium-up) {
      width: $hex-width;
      height: $hex-width;
      margin-#{$rdirection}: 14px;
      margin-#{$ldirection}: 0;
    }
    @include breakpoint($tablet-only) {
      width: 30px;
      height: 30px;
      margin-#{$rdirection}: 7px;
      margin-#{$ldirection}: 0;
    }
    &:hover,
    &.active {
      @include transition(box-shadow 0.3s);
      border: 1px solid $color-gray;
      outline: none;
    }
    &:nth-child(6n + 1) {
      margin-#{$ldirection}: 0;
    }
  }
}

.product-full__shade-description,
.product-quickshop__shade-description {
  font-size: 12px;
  line-height: 17px;
  min-height: 35px;
  margin: 10px 0;
}
